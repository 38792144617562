import CompanyRepository from "../repositories/CompanyRepository"
import AuthStore from "../stores/AuthStore"
import AuthLogic from "./AuthLogic"
import Model from "../models/Model"
import { cloneDeep } from "lodash"

export default class CompanyLogic {
  /** @description returns the current user Company Model
   */
  static async getMyCompany() {
    try {
      let user = AuthStore.me
      if (AuthStore.isSignedIn()) {
        if (!user) {
          await AuthLogic.loadMe()
          user = AuthStore.me
        }

        if (user) {
          const company = user.company
          if (company && company.uuid) {
            return this.getOne(company.uuid, {
              with_active_directory_credentials: true,
            })
          } else {
            return this.createNewCompany()
          }
        }
      } else {
        return null
      }
    } catch (error) {
      return null
    }
  }

  /** @description returns a Model of type companies with empty attributes
   */
  static createNewCompany() {
    const atttributes = {
      uuid: null,
      business_name: "",
      trade_name: "",
      siren_number: "",
      tva_number: "",
      address: {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postal_code: null,
      },
      city: "",
      postcode: "",
      phone_number: "",
      email_address: "",
      website: "",
      field_of_activity: "",
      headcount: 0,
      desktop_computer_count: 0,
      laptops_count: 0,
      mobile_phone_count: 0,
      is_self_editing_website: false,
      sharing_documents_frequency: "",
      uses: [],
      reseller: null,
      active_directory_credentials: {
        active: false,
        client_id: "",
        client_secret: "",
        tenant_id: "",
        filters: {
          countries: "",
          domains: "",
          ignoreWords: {
            displayName: "",
            givenName: "",
            surname: "",
          },
        },
      },
    }
    return atttributes
  }

  /** @description push new company to backend
   */
  static async createCompany(company) {
    const response = await CompanyRepository.createOne(JSON.stringify(company))
    return new Model(response.dataObject())
  }

  static async deleteCompany(company) {
    if (company) {
      const uuid = company.uuid
      await CompanyRepository.deleteOne(uuid)
      return true
    }
  }

  /** @description create or update company depending on id value */
  static async updateCompany(company) {
    if (company) {
      const uuid = company.uuid

      if (company.uuid) {
        // allow empty and null attributes to clear fields in DB
        let attributes = cloneDeep(company)

        for (let key in attributes) {
          let attr = attributes[key]
          if (attr == "") {
            attributes[key] = null
          }
        }

        attributes = JSON.stringify(attributes)

        const response = await CompanyRepository.patchOne(uuid, attributes)
        return response.dataObject()
      } else {
        const response = await CompanyRepository.createOne(company)
        return response.dataObject()
      }
    } else {
      throw new Error(`invalid company format`)
    }
  }

  static async getOne(companyUuid, params = {}) {
    const response = await CompanyRepository.getOne(companyUuid, params)
    // because backend might return a partilly filled company update an empty model to get all attributes
    let dbCompany = response.dataObject()
    if (!dbCompany.address) {
      dbCompany.address = {
        city: null,
        country: null,
        line1: null,
        line2: null,
        postal_code: null,
      }
    }
    if (!dbCompany.active_directory_credentials) {
      dbCompany.active_directory_credentials = {
        active: false,
        client_id: "",
        client_secret: "",
        tenant_id: "",
        filters: {
          countries: "",
          domains: "",
          ignoreWords: {
            displayName: "",
            givenName: "",
            surname: "",
          },
        },
      }
    } else {
      if (!dbCompany.active_directory_credentials.filters) {
        dbCompany.active_directory_credentials.filters = {
          ignoreWords: {
            displayName: "",
            givenName: "",
            surname: "",
          },
          countries: "",
          domains: "",
        }
      } else {
        if (!dbCompany.active_directory_credentials.filters.ignoreWords) {
          dbCompany.active_directory_credentials.filters.ignoreWords = {
            displayName: "",
            givenName: "",
            surname: "",
          }
        }
        if (!dbCompany.active_directory_credentials.filters.countries) {
          dbCompany.active_directory_credentials.filters.countries = ""
        }
        if (!dbCompany.active_directory_credentials.filters.domains) {
          dbCompany.active_directory_credentials.filters.domains = ""
        }
      }
    }
    let emptyCompany = this.createNewCompany()

    return { ...emptyCompany, ...dbCompany }
  }

  /** @description returns the AAD SSO Config
   */
  static async getAadSsoConfiguration(companyUuid, type) {
    const response = await CompanyRepository.getAadSsoConfiguration(
      companyUuid,
      type
    )
    return response.dataObject()
  }

  /** @description creates the AAD SSO Config
   */
  static async createAadSsoConfiguration(companyUuid, type) {
    const response = await CompanyRepository.createAadSsoConfiguration(
      companyUuid,
      type
    )
    return response.dataObject()
  }

  /** @description updates the AAD SSO Config
   */
  static async saveAadSsoConfiguration(companyUuid, params = {}) {
    const response = await CompanyRepository.patchAadSsoConfiguration(
      companyUuid,
      params
    )
    return response.dataObject()
  }

  /** @description deletes the AAD SSO Config
   */
  static async deleteAadSsoConfiguration(companyUuid, type) {
    const response = await CompanyRepository.deleteAadSsoConfiguration(
      companyUuid,
      type
    )
  }

  static async testAADFilter(companyUuid, params = {}) {
    const response = await CompanyRepository.testAADFilter(companyUuid, params)
    return response.dataObject()
  }

  static async setManagerMode(active) {
    let user = AuthStore.me
    const response = await CompanyRepository.setManagerMode(
      user.company.uuid,
      user.uuid,
      { is_manager_mode_active: active }
    )
    const updatedUser = response?.response?.data?.user
    AuthLogic.setMe({
      ...user,
      is_manager_mode_active: updatedUser.is_manager_mode_active,
    })
    location.reload()
  }

  static isFilled(attr, name) {
    if (attr[name] && "" != attr[name]) {
      return true
    }
    return false
  }

  static isBasicCompletionAchieved(company) {
    if (company) {
      if (
        this.isFilled(company, "website") &&
        this.isFilled(company, "business_name") &&
        this.isFilled(company, "trade_name") &&
        this.isFilled(company, "siren_number") &&
        this.isFilled(company, "phone_number") &&
        this.isFilled(company, "email_address")
      ) {
        return true
      }
    }
    return false
  }

  static getCompletionRate(company) {
    let rate = 0

    if (company) {
      rate += this.isFilled(company, "website") ? 5 : 0
      rate += this.isFilled(company, "business_name") ? 5 : 0
      rate += this.isFilled(company, "tva_number") ? 10 : 0
      rate += this.isFilled(company, "trade_name") ? 10 : 0
      rate += this.isFilled(company, "siren_number") ? 20 : 0
      rate += this.isFilled(company, "address") ? 10 : 0
      rate += this.isFilled(company, "phone_number") ? 10 : 0
      rate += this.isFilled(company, "email_address") ? 5 : 0
      rate += this.isFilled(company, "headcount") ? 5 : 0
      rate += this.isFilled(company, "uses") ? 10 : 0
      rate += this.isFilled(company, "field_of_activity") ? 5 : 0

      if (
        company.desktop_computer_count &&
        0 < company.desktop_computer_count
      ) {
        rate += 5
      }
    }

    return rate
  }

  static getCountryCodes() {
    return [
      "FR",
      "BE",
      "CH",
      "LU",
      "MC",
      "AD",
      "AF",
      "AL",
      "DZ",
      "AS",
      "AO",
      "AI",
      "AQ",
      "AG",
      "AR",
      "AM",
      "AW",
      "AU",
      "AT",
      "AZ",
      "BS",
      "BH",
      "BD",
      "BB",
      "BY",
      "BZ",
      "BJ",
      "BM",
      "BT",
      "BO",
      "BQ",
      "BA",
      "BW",
      "BV",
      "BR",
      "IO",
      "BN",
      "BG",
      "BF",
      "BI",
      "CV",
      "KH",
      "CM",
      "CA",
      "KY",
      "CF",
      "TD",
      "CL",
      "CN",
      "CX",
      "CC",
      "CO",
      "KM",
      "CD",
      "CG",
      "CK",
      "CR",
      "HR",
      "CU",
      "CW",
      "CY",
      "CZ",
      "CI",
      "DK",
      "DJ",
      "DM",
      "DO",
      "EC",
      "EG",
      "SV",
      "GQ",
      "ER",
      "EE",
      "SZ",
      "ET",
      "FK",
      "FO",
      "FJ",
      "FI",
      "GF",
      "PF",
      "TF",
      "GA",
      "GM",
      "GE",
      "DE",
      "GH",
      "GI",
      "GR",
      "GL",
      "GD",
      "GP",
      "GU",
      "GT",
      "GG",
      "GN",
      "GW",
      "GY",
      "HT",
      "HM",
      "VA",
      "HN",
      "HK",
      "HU",
      "IS",
      "IN",
      "ID",
      "IR",
      "IQ",
      "IE",
      "IM",
      "IL",
      "IT",
      "JM",
      "JP",
      "JE",
      "JO",
      "KZ",
      "KE",
      "KI",
      "KP",
      "KR",
      "KW",
      "KG",
      "LA",
      "LV",
      "LB",
      "LS",
      "LR",
      "LY",
      "LI",
      "LT",
      "MO",
      "MG",
      "MW",
      "MY",
      "MV",
      "ML",
      "MT",
      "MH",
      "MQ",
      "MR",
      "MU",
      "YT",
      "MX",
      "FM",
      "MD",
      "MN",
      "ME",
      "MS",
      "MA",
      "MZ",
      "MM",
      "NA",
      "NR",
      "NP",
      "NL",
      "NC",
      "NZ",
      "NI",
      "NE",
      "NG",
      "NU",
      "NF",
      "MK",
      "MP",
      "NO",
      "OM",
      "PK",
      "PW",
      "PS",
      "PA",
      "PG",
      "PY",
      "PE",
      "PH",
      "PN",
      "PL",
      "PT",
      "PR",
      "QA",
      "RO",
      "RU",
      "RW",
      "RE",
      "BL",
      "SH",
      "KN",
      "LC",
      "MF",
      "PM",
      "VC",
      "WS",
      "SM",
      "ST",
      "SA",
      "SN",
      "RS",
      "SC",
      "SL",
      "SG",
      "SX",
      "SK",
      "SI",
      "SB",
      "SO",
      "ZA",
      "GS",
      "SS",
      "ES",
      "LK",
      "SD",
      "SR",
      "SJ",
      "SE",
      "SY",
      "TW",
      "TJ",
      "TZ",
      "TH",
      "TL",
      "TG",
      "TK",
      "TO",
      "TT",
      "TN",
      "TR",
      "TM",
      "TC",
      "TV",
      "UG",
      "UA",
      "AE",
      "GB",
      "UM",
      "US",
      "UY",
      "UZ",
      "VU",
      "VE",
      "VN",
      "VG",
      "VI",
      "WF",
      "EH",
      "YE",
      "ZM",
      "ZW",
      "AX",
    ]
  }
}
