import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class CompanyRepository {
  /**
   * @returns {Promise}
   */
  static getOne(companyUuid, params = {}) {
    return Request.make("GET", `/${API_VERSION}/companies/${companyUuid}`, {
      params,
    })
  }

  /**
   * @returns {Promise}
   */
  static createOne(body = {}) {
    return Request.make("POST", `/${API_VERSION}/companies`, body)
  }

  /**
   * @returns {Promise}
   * @description applique des modifications partielles à une company
   */
  static patchOne(companyUuid, body = {}) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}`,
      body
    )
  }

  /**
   * @returns {Promise}
   * @description
   */
  static deleteOne(companyUuid, params = {}) {
    return Request.make("DELETE", `/${API_VERSION}/companies/${companyUuid}`, {
      params,
    })
  }

  /**
   * @returns {Promise}
   */
  static setManagerMode(companyUuid, userUuid, params = {}) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/users/${userUuid}/manager-mode`,
      params
    )
  }

  /**
   * @returns {Promise}
   */
  static testAADFilter(companyUuid, params = {}) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/azure-active-directory/users`,
      params
    )
  }

  /**
   * @returns {Promise}
   */
  static getAadSsoConfiguration(companyUuid, type = "api") {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso?type=${type}`
    )
  }

  /**
   * @returns {Promise}
   */
  static createAadSsoConfiguration(companyUuid, type = "api") {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso`,
      {
        type,
      }
    )
  }

  /**
   * @returns {Promise}
   */
  static patchAadSsoConfiguration(companyUuid, params) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso`,
      params
    )
  }

  /**
   * @returns {Promise}
   */
  static deleteAadSsoConfiguration(companyUuid, type = "api") {
    return Request.make(
      "DELETE",
      `/${API_VERSION}/companies/${companyUuid}/azure-sso/configuration-azure-sso`,
      {
        type,
      }
    )
  }
}
