import Request from "../services/Request"
import { API_VERSION } from "./ApiVersion"

export default class PhishingSimulationRepository {
  /**
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static getAll(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations`,
      { params }
    )
  }
  /**
   * @param companyUuid
   * @param simulation
   * @returns {Promise}
   */
  static patchOneSimulation(companyUuid, simulation) {
    return Request.make(
      "PATCH",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulation.id}`,
      simulation
    )
  }
  /**
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static getEmployees(companyUuid, params = {}) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/employees`,
      { params }
    )
  }
  /**
   * @param companyUuid
   * @returns {Promise}
   */
  static getStats(companyUuid) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/stats`
    )
  }
  /**
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static getDetails(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/results/${simulationId}`
    )
  }
  /**
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static exportResults(companyUuid, simulationId) {
    return Request.blob(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/exports/results`
    )
  }
  /**
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static exportAllResults(companyUuid, simulationId) {
    return Request.blob(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/exports/timeline`
    )
  }
  /**
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static createTest(companyUuid, simulationId) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/tests`
    )
  }
  /**
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static getTestResults(companyUuid, simulationId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/simulations/${simulationId}/tests/results`
    )
  }

  static getExerciseCompiledTemplates(companyUuid, exerciseId) {
    return Request.make(
      "GET",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/compiled-templates`
    )
  }

  static getExerciseCompiledWithTemplateTags(companyUuid, exerciseId, tags) {
    return Request.make(
      "POST",
      `/${API_VERSION}/companies/${companyUuid}/phishing/exercises/${exerciseId}/compiled-templates-with-tags`,
      tags
    )
  }
}
