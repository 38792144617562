import PhishingSimulationRepository from "../repositories/PhishingSimulationRepository"

export default class PhishingSimulationLogic {
  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getAll(companyUuid, params = {}) {
    const response = await PhishingSimulationRepository.getAll(
      companyUuid,
      params
    )
    return response.dataObject()
  }
  static async saveSimulation(companyUuid, simulation) {
    const response = await PhishingSimulationRepository.patchOneSimulation(
      companyUuid,
      simulation
    )
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @param params
   * @returns {Promise}
   */
  static async getEmployees(companyUuid, params = {}) {
    const res = await PhishingSimulationRepository.getEmployees(
      companyUuid,
      params
    )
    return res?.response?.data
  }
  /**
   *
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static async getDetails(companyUuid, simulationId) {
    const response = await PhishingSimulationRepository.getDetails(
      companyUuid,
      simulationId
    )
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @returns {Promise}
   */
  static async getStats(companyUuid) {
    const response = await PhishingSimulationRepository.getStats(companyUuid)
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static async exportResults(companyUuid, simulationId) {
    try {
      const res = await PhishingSimulationRepository.exportResults(
        companyUuid,
        simulationId
      )
      return res
    } catch (e) {
      return e.message
    }
  }
  /**
   *
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static async exportAllResults(companyUuid, simulationId) {
    try {
      const res = await PhishingSimulationRepository.exportAllResults(
        companyUuid,
        simulationId
      )
      return res
    } catch (e) {
      return e.message
    }
  }
  /**
   *
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static async createTest(companyUuid, simulationId) {
    const response = await PhishingSimulationRepository.createTest(
      companyUuid,
      simulationId
    )
    return response
  }
  /**
   *
   * @param companyUuid
   * @param simulationId
   * @returns {Promise}
   */
  static async getTestResults(companyUuid, simulationId) {
    const response = await PhishingSimulationRepository.getTestResults(
      companyUuid,
      simulationId
    )
    return response
  }
  /**
   *
   * @param companyUuid
   * @param exerciseId
   * @returns {Promise}
   */
  static async getExerciseCompiledTemplates(companyUuid, exerciseId) {
    const response =
      await PhishingSimulationRepository.getExerciseCompiledTemplates(
        companyUuid,
        exerciseId
      )
    return response.dataObject()
  }
  /**
   *
   * @param companyUuid
   * @param exerciseId
   * @param tags
   * @returns {Promise}
   */
  static async getExerciseCompiledWithTemplateTags(
    companyUuid,
    exerciseId,
    tags
  ) {
    const response =
      await PhishingSimulationRepository.getExerciseCompiledWithTemplateTags(
        companyUuid,
        exerciseId,
        tags
      )
    return response.dataObject()
  }
}
