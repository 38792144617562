<template>
  <v-menu :close-on-content-click="false" :nudge-width="300" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <div class="menu-button-container" v-on="on" v-bind="attrs">
        <v-avatar color="primary">
          <v-icon color="white">mdi-bell</v-icon>
        </v-avatar>
        <div class="nb-notif" v-if="nbNotif > 0">
          {{ nbNotif }}
        </div>
      </div>
    </template>

    <v-card class="notif-card">
      <v-card-title>{{ $t("notifications.title") }}</v-card-title>

      <v-divider></v-divider>

      <v-list class="notif-list">
        <template v-for="(notif, key) of notifications">
          <div v-bind:key="notif._id">
            <v-divider v-if="key !== 0"></v-divider>

            <v-list-item class="list-item">
              <span class="date">{{ notif.unix | datetime }}</span>

              <div class="notification">
                <!-- if it is a scan notif -->
                <div v-if="notif.type === 1" class="scan-notif">
                  <router-link
                    :to="{
                      name: 'cybercheck',
                      query: { scan: notif.metadata.scanID },
                    }"
                    tag="div"
                  >
                    <span class="notif-link">{{
                      $t("notifications.message", {
                        domain: notif.metadata.scanDomain,
                      })
                    }}</span>
                  </router-link>
                </div>

                <v-icon class="close-notif" @click="deleteNotif(notif)">
                  mdi-close
                </v-icon>
              </div>
            </v-list-item>
          </div>
        </template>

        <v-list-item v-if="notifications.length === 0">
          <span class="no-data">{{ $t("notifications.noData") }}</span>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import ZapAPILogic from "@/logics/ZapAPILogic"

export default {
  name: "op-notification-menu",

  props: {},

  data: () => ({}),

  computed: {
    notifications() {
      return this.$notifStore.state.notifs
    },

    nbNotif() {
      return this.notifications.length
    },
  },

  methods: {
    async requestNotifications() {
      await this.$notifStore.sync()
    },

    deleteNotif(notif) {
      ZapAPILogic.deleteNotification(notif)
        .then((success) => {
          this.$notifStore.sync()
        })
        .catch((e) => {
          //console.log("error: ", e)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.menu-button-container {
  position: relative;
}

$nb-notif-size: 20px;
.nb-notif {
  position: absolute;
  background-color: red;
  color: white;
  font-weight: bold;
  width: $nb-notif-size;
  height: $nb-notif-size;
  text-align: center;
  border-radius: 50%;
  vertical-align: middle;
  line-height: $nb-notif-size;
  bottom: 0;
  right: 0;
  font-size: $nb-notif-size / 2 + $nb-notif-size / 5;
}

.notif-card {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
}

.notif-list {
  flex-grow: 1;
  overflow-y: scroll;
}

.list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;
}

.date {
  color: silver;
  font-size: 12px;
  margin-left: 10px;
}

.notification {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.scan-notif {
  display: flex;
  flex-direction: column;
}

.notif-link {
  cursor: pointer;
}

.close-notif {
  color: red;
  margin: 0 5px;
}

.no-data {
  color: silver;
  font-style: italic;
}
</style>
