<template>
  <v-app :style="getStyle()">
    <!--
              :mini-variant="$vuetify.breakpoint.lgAndUp && !PageStore.drawer"
      :permanent="$vuetify.breakpoint.lgAndUp"
      -->

    <v-navigation-drawer
      v-if="PageStore.showDrawer"
      v-model="PageStore.drawer"
      width="300"
      :floating="true"
      app
    >
      <template v-slot:prepend>
        <v-list-item dense class="my-3" :class="{ 'ml-0': PageStore.drawer }">
          <v-list-item-content v-if="PageStore.drawer">
            <v-img
              contain
              height="36px"
              position="center left"
              :alt="`${companyName} logo`"
              :src="logo"
            />
          </v-list-item-content>

          <v-list-item-action>
            <v-app-bar-nav-icon
              color="primary"
              style="margin-left: -6px !important"
              @click.stop="PageStore.drawer = !PageStore.drawer"
            >
            </v-app-bar-nav-icon>
          </v-list-item-action>
        </v-list-item>
      </template>

      <v-list dense id="drawer-list" class="pt-0">
        <v-list-item-group>
          <template v-for="(item, key) in links">
            <v-list-item
              v-if="item.type == 'single'"
              :key="key"
              active-class="primary font-weight-bold"
              :to="item.route"
              :href="item.href"
            >
              <template v-slot:default="{}">
                <v-list-item-action>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title :class="getItemTitleClass()">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list-group v-else :key="item.icon" no-action color="blue-dark">
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title :class="getItemTitleClass()">{{
                    item.text
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(child, key) in item.children"
                :key="key"
                active-class="primary font-weight-bold"
                :to="child.route"
                :href="child.href"
                :target="child.target"
              >
                <v-list-item-content class="ml-3">
                  <v-list-item-title :class="getItemTitleClass()">
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-menu v-if="canViewSupportMenu"
          ><template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              block
              class="menu-footer-button mb-3"
              v-bind="attrs"
              v-on="on"
              ><v-img
                contain
                width="27px"
                height="27px"
                position="center left"
                :alt="footerLogo.title"
                :src="footerLogo.src"
                v-if="reseller || company"
              />
              <v-icon v-else class="mr-2">mdi-account</v-icon>
              <div
                class="d-flex flex-column align-start text-h4 menu-footer-button-label"
              >
                <div class="font-weight-medium">
                  {{ supportName }}
                </div>
                <div>{{ $t("navigation.footer.contact") }}</div>
              </div>
              <v-icon right>mdi-chevron-up</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item
              ><v-list-item-content
                ><v-list-item-subtitle>{{
                  phone
                }}</v-list-item-subtitle></v-list-item-content
              ></v-list-item
            >
            <v-list-item :href="email"
              ><v-list-item-content
                ><v-list-item-subtitle>{{
                  email ? $t("navigation.footer.sendMail") : ""
                }}</v-list-item-subtitle></v-list-item-content
              ></v-list-item
            >
          </v-list></v-menu
        >
      </template>
    </v-navigation-drawer>

    <!-- 
      :showBurger="!$vuetify.breakpoint.lgAndUp"
      :showLogo="!$vuetify.breakpoint.lgAndUp"
      -->

    <op-toolbar v-if="PageStore.showToolbar" :hide-on-scroll="true" app>
    </op-toolbar>

    <v-main>
      <op-browser-alert> </op-browser-alert>

      <router-view />
    </v-main>

    <op-footer> </op-footer>
    <v-snackbar bottom :value="updateExists" :timeout="-1" color="primary">
      <div class="d-flex">
        <div class="text-body-1">{{ $t("appUpdate.updateDescription") }}</div>
        <v-btn text dark primary full @click="refreshApp">{{
          $t("appUpdate.updateLabel")
        }}</v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<style lang="scss">
#drawer-list {
  .v-list-item-group > .v-list-item--active,
  .primary.v-list-item--active {
    color: white !important;
    caret-color: white !important;

    .primary--text {
      color: inherit !important;
      caret-color: inherit !important;
    }

    .v-list-item__title {
      caret-color: white !important;
      color: white !important;
      font-weight: bold !important;
    }
  }
}

.v-application--is-ltr .v-list-item__action:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child),
.v-application--is-ltr .v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 0;
}

.menu-footer-button.v-btn {
  text-transform: none;

  .menu-footer-button-label {
    width: 75%;
  }
}
</style>

<script>
import PageStore from "@/stores/PageStore"
import CustomizationStore from "@/stores/CustomizationStore"
import AppUpdateMixin from "@/mixins/AppUpdate.mixins.js"
import CompanyLogic from "@/logics/CompanyLogic"

export default {
  name: "default-layout",
  mixins: [AppUpdateMixin],
  async mounted() {
    this.company = await CompanyLogic.getMyCompany()
    this.$gtmLogic.updateUserStatusAndPush()
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
    this.onResize()
  },
  computed: {
    canViewSupportMenu() {
      return (
        this.$authStore.state.me?.company_roles &&
        !this.$authStore.state.me.company_roles.includes(
          "company-user-manager"
        ) &&
        !this.$authStore.state.me.company_roles.includes(
          "company-human-resource-manager"
        ) &&
        !this.$authStore.state.me.company_roles.includes(
          "company-accounting-manager"
        )
      )
    },
    supportName() {
      const customSupportInfo = CustomizationStore.getEmails()?.support
      if (customSupportInfo) {
        return customSupportInfo.name
      } else {
        return this.$t("navigation.footer.expertLabel")
      }
    },
    phone() {
      const phoneNumber = CustomizationStore?.getPhoneNumber()
      if (phoneNumber) {
        return phoneNumber
      } else {
        return this.$t("navigation.footer.noPhoneNumber")
      }
    },
    email() {
      const customSupportInfo = CustomizationStore.getEmails()?.support
      if (customSupportInfo) {
        return `mailto:${customSupportInfo.email}`
      } else {
        return "mailto:support@oppens.fr"
      }
    },
    footerLogo() {
      let logoContent = {
        src: this.$customizationStore.getLogos()?.thumbnail,
        title: this.supportName,
      }
      return logoContent
    },
    logo() {
      return this.$customizationStore.getLogos()?.default
    },
    companyName() {
      return this.$customizationStore.getCompany()?.name
    },
    reseller() {
      return this.company?.reseller
    },
    links() {
      if (this.user && !this.isAnonymous) {
        return this.connectedLinks
      }
      return this.disconnectedLinks
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light"
    },
    user() {
      return this.$authStore.state.me
    },
    isAnonymous() {
      if (this.$authStore.state.me && this.$authStore.state.me.is_anonymous) {
        return true
      }
      return false
    },
    disconnectedLinks() {
      const links = [
        {
          text: this.$i18n.t("navigation.news"),
          icon: "mdi-newspaper",
          href: "https://www.oppens.fr/blog-cybersecurite/",
          target: "_self",
          type: "single",
        },
        {
          text: this.$i18n.t("navigation.solutions"),
          icon: "mdi-cart-outline",
          route: {
            name: "store_main_home",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("label.connexion"),
          icon: "mdi-account-arrow-right-outline",
          route: {
            name: "login",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("label.create_account"),
          icon: "mdi-account-plus",
          route: {
            name: "register",
          },
          type: "single",
        },
      ]
      return links
    },
    connectedLinks() {
      const defaultLinks = [
        {
          text: this.$i18n.t("navigation.dashboard"),
          icon: "$homeMenuIcon",
          route: {
            name: "member-dashboard",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("navigation.phishing"),
          icon: "$phishingMenuIcon",
          type: "group",
          children: [
            {
              text: this.$i18n.t("navigation.phishing-summary"),
              route: {
                name: "member-phishing",
              },
            },
            {
              text: this.$i18n.t("navigation.phishing-history"),
              route: {
                name: "member-phishing-history",
              },
            },
          ],
        },
        {
          text: this.$i18n.t("navigation.courses"),
          icon: "$courseMenuIcon",
          type: "group",
          children: [
            {
              text: this.$i18n.t("navigation.course-overview"),
              route: {
                name: "member-course-overview",
              },
            },
            {
              text: this.$i18n.t("navigation.course-history"),
              route: {
                name: "member-course-history",
              },
            },
          ],
        },
        {
          text: this.$i18n.t("navigation.diagnosticsAndConsulting"),
          icon: "$consultingMenuIcon",
          type: "group",
          children: [
            {
              text: this.$i18n.t("navigation.diagnostics"),
              route: {
                name: "member-assessment",
              },
            },
            {
              text: this.$i18n.t("navigation.cybercheck"),
              route: {
                name: "cybercheck",
              },
            },
            {
              text: this.$i18n.t("navigation.rgpd"),
              route: {
                name: "member-rgpd-scan",
              },
            },
          ],
        },
        /* {
          text: this.$i18n.t("navigation.solutions"),
          icon: "mdi-cart-outline",
          route: {
            name: "store_main_home",
          },
        },
        {
          text: this.$i18n.t("navigation.news"),
          icon: "mdi-newspaper",
          href: "https://www.oppens.fr/blog-cybersecurite/",
        }, */
      ]

      const additionalLinks = [
        {
          text: this.$i18n.t("navigation.member-profile"),
          icon: "mdi-account",
          route: {
            name: "member-profile",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("navigation.company-profile"),
          icon: "mdi-domain",
          route: {
            name: "company-profile",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("navigation.collaborators"),
          icon: "mdi-account-multiple",
          route: {
            name: "member-collaborators",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("navigation.accessRights"),
          icon: "mdi-account-key",
          route: {
            name: "member-access-rights",
          },
          type: "single",
        },
        {
          text: this.$i18n.t("navigation.my-documents"),
          icon: "mdi-file-document-outline",
          route: {
            name: "my_documents",
          },
        },
        {
          text: this.$i18n.t("navigation.disconnect"),
          icon: "mdi-power",
          route: {
            name: "logout",
          },
          type: "single",
        },
      ]

      const disabledRoutes = CustomizationStore.getDisabledRoutes()
      for (
        let linkIndex = defaultLinks.length - 1;
        linkIndex >= 0;
        linkIndex--
      ) {
        const parentNode = defaultLinks[linkIndex]
        if (parentNode.type === "single") {
          if (disabledRoutes.includes(parentNode.route.name)) {
            defaultLinks.splice(linkIndex, 1)
          }
        } else {
          for (
            let childIndex = parentNode.children.length - 1;
            childIndex >= 0;
            childIndex--
          ) {
            const element = parentNode.children[childIndex]
            if (disabledRoutes.includes(element.route.name)) {
              parentNode.children.splice(childIndex, 1)
            }
          }
          if (parentNode.children.length === 0) {
            defaultLinks.splice(linkIndex, 1)
          }
        }
      }
      if (this.mobileMenu) {
        return defaultLinks.concat(additionalLinks)
      }
      return defaultLinks
    },
  },

  data: () => ({
    PageStore,
    mobileMenu: false,
    company: null,
  }),

  methods: {
    onResize() {
      this.mobileMenu = window.innerWidth <= 950 ? true : false
    },
    getStyle() {
      let obj = {
        overflow: "hidden",
      }
      const _theme = this.$vuetify.theme.dark ? "dark" : "light"
      obj["background"] = this.$vuetify.theme.themes[_theme].background
      return obj
    },
    getItemTitleClass() {
      return !this.$vuetify.theme.dark ? "blue-dark--text" : "white--text"
    },
  },
}
</script>
