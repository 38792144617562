import Vue from "vue"
import axios from "axios"
import AuthLogic from "@/logics/AuthLogic"
import JsonApiResponse from "./JsonApiResponse"

export const EventBus = new Vue()

class Request {
  constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_API_HOST,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // 'Content-type': 'application/json',
        // 'Accept': `application/vnd.api.${process.env.VUE_APP_API_VERSION}+json`,
      },
      // withCredentials: true,
    })

    this.client.interceptors.request.use((config) => {
      const tokens = AuthLogic.getTokens()

      if (tokens) {
        config.headers["Authorization"] = `Bearer ${tokens.access_token}`
      }

      return Promise.resolve(config)
    })
  }

  /**
   * Make a new AJAX request.
   *
   * @param {string} method
   * @param {string} url
   * @param {Object} config
   * @returns {Promise}
   */
  async make(method, url, config = {}) {
    try {
      let response = await this.client[method.toLowerCase()](url, config)
      return new JsonApiResponse(response)
    } catch (error) {
      return await this.parseError(error, url)
    }
  }

  /**
   * Get blob from AJAX request (to download files).
   *
   * @param {string} method
   * @param {string} url
   * @param {Object} config
   * @returns {Promise}
   */
  async blob(method, url, config = {}) {
    try {
      let response
      if (method.toLocaleLowerCase() === "post") {
        response = await this.client.post(url, config, {
          responseType: "blob",
        })
      } else {
        response = await this.client[method.toLowerCase()](url, {
          ...config,
          responseType: "blob",
        })
      }
      return response
    } catch (error) {
      return await this.parseError(error, url)
    }
  }

  /**
   * Check error response and launch custom action
   *
   * @param error
   * @returns {Promise<void>}
   */
  async parseError(error, url) {
    if (
      error &&
      error.response &&
      401 === error.response.status &&
      "/login" != url
    ) {
      const accessToken = AuthLogic.getTokens()

      if (
        accessToken &&
        Object.prototype.hasOwnProperty.call(accessToken, "refresh_token")
      ) {
        try {
          await AuthLogic.refreshToken(accessToken.refresh_token)
          let response = await this.client.request(error.config)
          return new JsonApiResponse(response)
        } catch (error) {
          AuthLogic.removeMe()
          AuthLogic.removeTokens()
          EventBus.$emit("401-unauthorized")
          throw error
        }
      } else {
        AuthLogic.removeMe()
        AuthLogic.removeTokens()
        EventBus.$emit("401-unauthorized")
        throw error
      }
    } else {
      throw error
    }
  }
}

export default new Request()
